import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import VodEntry from "../../components/vodentry";
import Layout from "../../components/layout";

import { Tab } from "@headlessui/react";

const VODListingPage = ({ data }) => {
  return (
    <Layout pageTitle="KSEA NBM VODs">
      <h1 className="text-2xl font-bold pb-6">All On-Demand Videos</h1>

      <div className="mb-20">
        <Tab.Group defaultIndex={0}>
          <Tab.List className="btn-group">
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Auburn
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              El Segundo
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Stafford
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Teams
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Awards
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Day One
            </Tab>
            
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="">
              {data.teamOne.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.teamTwo.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.teamThree.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.teams.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.awards.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.dayOne.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            
          </Tab.Panels>
        </Tab.Group>
      </div>

      <div className="my-10">
        &nbsp;
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    teamOne: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Auburn" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, height: 180)
            }
          }
          date
        }
      }
    }

    teamTwo: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "El Segundo" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    teamThree: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Stafford" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    teams: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Teams" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    awards: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Awards" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    dayOne: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Day One" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    dayTwo: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Day Two" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    dayThree: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Day Three" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }


  }
`;

export default VODListingPage;
